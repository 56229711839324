.fa-ellipsis {
color:rgb(142, 141, 141);

}

.menu-trigger{

    cursor: pointer;
    width: fit-content; 
    float: right;
    margin-right: 20px;
  
  
}
#modal-content{
   background-color: none;;
}

.dropdown-menu{
    box-shadow: 4px 3px 4px black;
  
    color: #fff;
    background-color: rgb(44, 44, 44);
    border-radius: 5px;
    text-align: center;
    width:150px;
    /* font-family: Helvetica, sans-serif; */
}

.dropdown-menu ul li{
    padding:10px 0;
    text-align: center; 
     border-top: 1px solid rgba(242, 241, 241, 0.05);
}

.dropdown-menu.active{
    opacity: 1;
    visibility: visible;
    transform: translate(10px, 0);
    transition: var(--speed) ease;
    box-shadow: 5px 5px 5px 0px rgb(29, 29, 29);
    z-index: 4px;
    cursor: pointer;

}

#Close{
    margin-left: 30px;
}

.dropdown-menu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: var(--speed) ease;


}

ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
   
}

#Delete {
    color: rgb(220, 33, 33);
    background-color: rgb(44, 44, 44); ;
    border:none;
    margin-left: 25px;
    cursor:pointer;
    /* font-family: Helvetica, sans-serif; */
    font-weight: bolder;
    font-size: 17px;
   
}
#Delete:hover{
    background-color: rgb(74, 74, 74);
}

#Edit{

    background-color: rgb(44, 44, 44); ;
    border:none;
    color:#fff;
    cursor:pointer;
    /* font-family: Helvetica, sans-serif; */
    font-weight: bolder;
    font-size: 17px;

}
#Edit:hover{
    background-color: rgb(74, 74, 74);
}

.dropdownItem:hover {
    background-color: rgb(74, 74, 74);
  } 



