#Text, #Photo, #Quote,#Link{
    background-color: rgb(44, 44, 44) ;
    border:none;
    color:#fff;
    /* font-family: Helvetica, sans-serif; */
}

.Navicons button{
    background-color: rgb(44, 44, 44);
    border: none;
    
}
.Navicons p{
    margin: 0;
    margin-bottom: 5px;
}

.site-developers {
    color: white;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.social-links {
    display: flex;
    gap: 10px;
    margin-top: -10px;
}