.post-modal {
    width: 500px;
    margin: 20px auto;
    background-color: rgb(44,44,44);
    color: white;
    border-radius: 5px;
}

.post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.post-operations {
    display: flex;
    justify-content: flex-end;
}

.post-actions {
    display: flex;
    justify-content: flex-end;
}

.icon-button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: black;
    font-size: 16px;
}

.post-header h3 {
    font-size: 16px;
    color: white;
}

.post-header p {
    font-size: 14px;
    color: #007BFF;
}

.post-footer {
    padding: 25px;
    padding-bottom: 55px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: flex-start;
}

.option {
    border: none;
    /* background: none; */
    color: white;
}

.dropdown-options {
    display: flex;
    justify-content: flex-start;
}