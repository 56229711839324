/* TODO Add site wide styles */
#root {
    /*   
        /* max-width: 1000px; 
        margin: 0 auto; 
        padding: 20px;  */
    /* border:1px solid limegreen; 
        ; */

    display: flex;
    justify-content: center;
    /* border:1px solid rgb(161, 159, 159); */


}

body {
    background-color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: lighter;




}