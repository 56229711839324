.heading_posts {
    color: white;
}

body {
    /* background-color: white; */
    color: black;
}

/* .post {
    border: dashed red 1px;
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;

} */

/* .postimages{
    width:150px;
    height:75px;
} */

.all-posts-container{
    display: flex;
    flex-direction: column;
}

.user-username{
    border-bottom: solid rgb(177, 176, 176) 1px;
    color: white;
    padding: 15px;
    font-size: 20px;
    font-weight: bold;
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
}