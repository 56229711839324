.post-modal {
    width: 500px;
    margin: 20px auto;
    background-color:rgb(44,44,44);
    border-radius: 5px;
}

.post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.post-operations {
    display: flex;
    justify-content: flex-end;
}

.post-actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}

.icon-button {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: white;
    font-size: 16px;
}

.post-header h3 {
    font-size: 16px;
    color: white;
}

.post-header p {
    font-size: 14px;
    color: #007BFF;
}

.post-footer {
    padding: 50px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: flex-start;
}

.option {
    border: none;
    background: none;
    color: black;

}

.dropdown-options {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}


.user-username{
    border-bottom: solid black 1px;
    color: black;
    padding: 15px;
}

.post-title{
    /* font-weight: bold; */
    font-size: 20px;
    margin: 10px;
}

.post-textContent{
    margin: 10px;
    display: grid;
    
}

.postimages{
    display: flex;
    width: 425px;
    height: fit-content;
    justify-self: center;
}


.hide-edit-image {
    display: none;
}

.editButton {
    border: none;
}

.post-operations button{
    border: none;
    background-color: rgb(44,44,44);
    color: white;
}

.trashcan{
    margin-right: 5px;
}
