.quote-modal{
    background-color: rgb(44, 44, 44); 
    width:400px;
    height:300px;
  
    /* align-items: center; */

}
.quote-modal-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:25px;
    height:70%;
  

}
.user-tag{
    margin: 20px;
    color: white;
 
}

.author-content{
    background-color: rgb(44, 44, 44); 
    border: 1px solid rgb(165, 165, 165, 0.3);
    border-radius: 4px;
    width:80%;
    text-align: center;
    font-size: large;
    color: white;
    


}

.post-content{
    background-color: rgb(44, 44, 44); 
    border: 1px solid rgb(165, 165, 165, 0.3);
    width:80%;
    font-size: x-large;
    color: white;
}

.buttom-items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:50px;
}
#quoteSubmitButton{
    color:white;
    background-color: rgb(0, 145, 255);
    border:none;
    border-radius: 3px; 
    height:30px;
    width:80px;
}

.close-quote-modal{
    color:white;
    background-color: rgb(0, 145, 255);
    border:none;
    border-radius: 3px;
    height:30px;
    width:80px;
}

