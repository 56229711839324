

.EditForm{
background-color: rgb(44, 44, 44); 
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
gap:30px;
width:400px;
height:220px;


}
.edit-header{
    /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
    margin-bottom: 5px;
    color: white;
}
.editFormData{
  
    padding-left:70px;
    padding-right: 70px;
}

.editTextText{
    background-color: rgb(44, 44, 44); 
    color:#fff;
    width:80%;
    height: 90%;
   
}

.editTextText:hover{
    border:1px solid  rgb(0, 145, 255);;

}
.editSubmitButton{
    margin-bottom: 5px;
    border:none;
    background-color: rgb(0, 145, 255);
    color:#fff;
    width:80px;
    height: 60px;
    border-radius: 5px;;

}

.textForEditNote{
    background-color: rgb(44, 44, 44); 
    border:1px solid  rgb(0, 145, 255);
    color:#fff;
}



.Save{
    background-color: rgb(0, 145, 255) ;
    border:none;
    color:#fff;
    width:100px;
    height:45px;
    border-radius: 5px;;
    margin-bottom: 20px;;


}




.confirmDelete{
    text-align: center;
 
}

.confirm{
    background-color: rgb(0, 145, 255);
    padding:10px;
    border-radius: 8px;
    border:none;
    padding: 10px 10px;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-size: 20px;;

}
.deny{
    background-color: rgb(128, 128, 128);
    padding:10px;
    border-radius: 8px;
    border:none;
    padding: 10px 10px;
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
    font-size: 20px;;
}

.buttonItems{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px 10px;
  
}
.delete-message{
   
    margin:15px;
}
